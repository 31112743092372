import React, { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase-config";
import { getDocs, collection, doc, getDoc } from "firebase/firestore";
import GroupsIcon from "@mui/icons-material/Groups";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";
import PaidIcon from "@mui/icons-material/Paid";
import UserComp from "./UserComp";
import AddComp from "./AddComp";
import MoneyManagementComp from "./MoneyManagementComp";
import DeleteGameComp from "./DeleteGameComp";
import "./style/UserComp.css";
import "./style/AddComp.css";
import "./style/adminDeleteGame.css";
import "./style/AdminGamesSection.css";

const COMPONENTS = {
  USERS: "users",
  ADD: "add",
  MONEY_MANAGEMENT: "moneyManagement",
  DELETE_GAME: "deleteGame",
};

const HEADINGS = {
  USERS: "Nutzer verwalten",
  ADD: "Neue Spiele hinzufügen",
  MONEY_MANAGEMENT: "Geldverwaltung",
  DELETE_GAME: "Spiel löschen",
};

const AdminDashboard = () => {
  const [currentComponent, setCurrentComponent] = useState(COMPONENTS.USERS);
  const [adminData, setAdminData] = useState([]);
  const [upcomingGames, setUpcomingGames] = useState([]);
  const [pastGames, setPastGames] = useState([]);
  const [pastGamesNoResult, setPastGamesNoResult] = useState([]);
  const [adminVereinsId, setAdminVereinsId] = useState(null);

  useEffect(() => {
    const gamesWithNoResult = pastGames.filter((game) => !game.ergebnis);
    setPastGamesNoResult(gamesWithNoResult);
  }, [pastGames]);

  useEffect(() => {
    fetchAdminVereinsId();
  }, []);

  useEffect(() => {
    if (adminVereinsId) {
      fetchAdminData(adminVereinsId);
      fetchGames(adminVereinsId);
    }
  }, [adminVereinsId]);

  const fetchAdminVereinsId = async () => {
    if (auth.currentUser) {
      try {
        const adminDoc = doc(db, "users", auth.currentUser.uid);
        const adminSnap = await getDoc(adminDoc);
        if (adminSnap.exists()) {
          setAdminVereinsId(adminSnap.data().vereinsId);
        } else {
          console.log("Admin-Daten nicht gefunden");
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Admin-Daten:", error);
      }
    } else {
      console.log("Benutzer nicht angemeldet");
    }
  };

  const fetchAdminData = async (vereinsId) => {
    try {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((user) => user.vereinsId === vereinsId);
      setAdminData(usersList);
    } catch (error) {
      console.error("Fehler beim Abrufen der Benutzerdaten:", error);
    }
  };

  const fetchGames = async (vereinsId) => {
    try {
      console.log("Fetching games for vereinsId:", vereinsId); // Log the vereinsId
      const gamesCollection = collection(db, "vereine", vereinsId, "games");
      const gamesSnapshot = await getDocs(gamesCollection);
      const gamesList = gamesSnapshot.docs.map((doc) => {
        const data = doc.data();
        console.log("Game data:", data); // Konsolenausgabe der Spieldaten
        return {
          id: doc.id,
          ...data,
          date: data.date.toDate(), // Konvertieren des Timestamp-Feldes in ein Date-Objekt
        };
      });
      gamesList.sort((a, b) => a.date - b.date);
      const now = new Date();
      const upcoming = gamesList.filter((game) => game.date > now);
      const past = gamesList.filter((game) => game.date <= now);
      setUpcomingGames(upcoming);
      setPastGames(past);
      console.log("Fetched past games:", past);
      console.log("Fetched upcoming games:", upcoming);
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    }
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case COMPONENTS.USERS:
        return <UserComp adminData={adminData} />;
      case COMPONENTS.ADD:
        return <AddComp />;
      case COMPONENTS.MONEY_MANAGEMENT:
        return (
          <MoneyManagementComp
            pastGamesNoResult={pastGamesNoResult}
            pastGames={pastGames}
            adminData={adminData}
          />
        );
      case COMPONENTS.DELETE_GAME:
        return <DeleteGameComp upcomingGames={upcomingGames} />;
      default:
        return <UserComp adminData={adminData} />;
    }
  };

  const handleButtonClick = (comp) => {
    setCurrentComponent(comp);
  };

  return (
    <div className="adminMainDiv">
      <div className="adminHeader">
        <h1>Admin Dashboard</h1>
        <button onClick={() => signOut(auth)}>
          <LogoutIcon />
        </button>
      </div>
      <div className="adminContent">
        <div className="adminHeadingDiv">
          <h1>{HEADINGS[currentComponent.toUpperCase()]}</h1>
        </div>
        {renderComponent()}
      </div>
      <div className="navBar">
        <div className="navBarButtonDiv">
          <div
            className="navBarIconDiv"
            onClick={() => handleButtonClick(COMPONENTS.USERS)}
          >
            <GroupsIcon style={{ fontSize: "2rem" }} />
          </div>
        </div>
        <div className="navBarButtonDiv">
          <div
            className="navBarIconDiv navbarHighlightDiv"
            onClick={() => handleButtonClick(COMPONENTS.ADD)}
          >
            <AddIcon style={{ fontSize: "2rem" }} />
          </div>
        </div>
        <div
          className="navBarButtonDiv"
          onClick={() => handleButtonClick(COMPONENTS.MONEY_MANAGEMENT)}
        >
          <div className="navBarIconDiv">
            <PaidIcon style={{ fontSize: "2rem" }} />
          </div>
        </div>
        <div
          className="navBarButtonDiv"
          onClick={() => handleButtonClick(COMPONENTS.DELETE_GAME)}
        >
          <div className="navBarIconDiv">
            <DeleteIcon style={{ fontSize: "2rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
