import React, { useState } from "react";
import {
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import JoinRequests from "./helpers/JoinRequests";
import BetForPlayer from "./helpers/BetForPlayer"; // Import the BetForPlayer component
import { db } from "../../firebase-config";

const UserComp = ({ adminData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeUser, setActiveUser] = useState(null);
  const [showBets, setShowBets] = useState(false);

  const filteredUsers = adminData.filter(
    (user) =>
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) && user.role !== "admin"
  );

  const handleExpandClick = (user) => {
    setActiveUser(activeUser === user ? null : user);
  };

  const handleGeldAufladen = async (user, amount) => {
    const userRef = doc(db, "users", user.id);

    const confirmDelete = window.confirm(
      `Möchten Sie wirklich ${amount} auf das Konto von ${
        user.firstName + user.lastName
      } überweisen?`
    );

    if (!confirmDelete) {
      return;
    }

    try {
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const currentKontoStand = userSnapshot.data().kontoStand;
        const updatedKontoStand = currentKontoStand + amount;

        const currentTransaktionen = userSnapshot.data().transaktionen;
        currentTransaktionen.push({
          betrag: amount,
          date: Timestamp.now(),
          type: "aufladen",
        });

        await updateDoc(userRef, {
          kontoStand: updatedKontoStand,
          transaktionen: currentTransaktionen,
        });

        console.log(
          `Kontostand von ${user.firstName} ${user.lastName} um ${amount}€ aufgeladen. Neuer Kontostand: ${updatedKontoStand}€`
        );
      } else {
        console.error(`Benutzer ${user.id} nicht gefunden.`);
      }
    } catch (error) {
      console.error("Fehler beim Aufladen des Kontostands:", error);
    }
  };

  const handleUserDelete = async (user) => {
    const userRef = doc(db, "users", user.id);

    const confirmDelete = window.confirm(
      `Möchten Sie den Nutzer ${
        user.firstName + user.lastName
      } wirklich löschen?`
    );

    if (!confirmDelete) {
      return;
    }

    try {
      await deleteDoc(userRef);
      console.log(
        `Benutzer ${user.firstName} ${user.lastName} erfolgreich gelöscht.`
      );
    } catch (error) {
      console.error("Fehler beim Löschen des Benutzers:", error);
    }
  };

  const handleShowBets = (user) => {
    setActiveUser(user);
    setShowBets(true);
  };

  return (
    <div className="adminUserComp">
      <JoinRequests />
      <input
        type="text"
        placeholder="Benutzer suchen..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="searchInput"
      />
      {filteredUsers.length > 0 ? (
        <div className="adminUserData">
          {filteredUsers.map((user) => (
            <div
              className={
                activeUser === user ? "adminExpanded" : "adminNotExpanded"
              }
              key={user.id}
            >
              <div className="adminUserDataInnerDiv">
                <p>Name: {user.firstName + " " + user.lastName}</p>
                <p>Kontostand: {user.kontoStand}€</p>
                <div
                  className="expandIcon"
                  onClick={() => handleExpandClick(user)}
                  style={{ cursor: "pointer" }}
                >
                  {activeUser === user ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </div>
              </div>
              {activeUser === user && (
                <div className="adminDetails">
                  <div>
                    <h3>Aktionen:</h3>
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        const amount = parseFloat(
                          e.target.elements.amount.value
                        );
                        if (!isNaN(amount) && amount > 0) {
                          handleGeldAufladen(user, amount);
                        } else {
                          alert("Bitte geben Sie einen gültigen Betrag ein.");
                        }
                      }}
                    >
                      <label htmlFor="amount">Betrag:</label>
                      <input
                        type="number"
                        id="amount"
                        name="amount"
                        step="0.01"
                        min="0.01"
                        placeholder="Betrag eingeben"
                        required
                      />
                      <button style={{ height: "auto" }} type="submit">
                        Geld aufladen
                      </button>
                    </form>

                    <button
                      className="deleteBtn"
                      style={{ height: "auto", padding: "5px 10px" }}
                      onClick={() => handleUserDelete(user)}
                    >
                      User löschen
                    </button>
                    <button
                      className="betsBtn"
                      style={{ height: "auto", padding: "5px 10px" }}
                      onClick={() => handleShowBets(user)}
                    >
                      Für Benutzer wetten
                    </button>
                  </div>
                  <div>
                    <h3>Email:</h3>
                    <p>{user.email}</p>
                  </div>

                  <h3>Transaktionen:</h3>
                  {user.transaktionen?.length > 0 ? (
                    user.transaktionen
                      .sort((a, b) => b.date.toDate() - a.date.toDate())
                      .map((transaktion, index) => (
                        <div
                          className={
                            transaktion.type === "aufladen"
                              ? "adminTransaktionAufladen"
                              : "adminTransaktionDecrease"
                          }
                          key={index}
                        >
                          <p>
                            {transaktion.date ? (
                              new Date(
                                transaktion.date.toDate()
                              ).toLocaleDateString("de-DE")
                            ) : (
                              <p>Datum nicht verfügbar</p>
                            )}
                          </p>
                          <p style={{ fontFamily: "roboto" }}>
                            {transaktion.type === "aufladen" ? "+" : "-"}
                            {transaktion.betrag}€
                          </p>
                        </div>
                      ))
                  ) : (
                    <p>Keine Transaktionen gefunden</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>Keine Benutzer gefunden</p>
      )}
      {showBets && activeUser && (
        <BetForPlayer
          userId={activeUser.id}
          onClose={() => setShowBets(false)}
        />
      )}
    </div>
  );
};

export default UserComp;
