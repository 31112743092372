import React, { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import useUserData from "./useUserData";
import { format } from "date-fns";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import "./style/Settings.css";

const Settings = ({ onComponentChange, activeComponent }) => {
  const { userData } = useUserData();
  const [logoUrl, setLogoUrl] = useState("");
  const [vereinName, setVereinName] = useState("");

  useEffect(() => {
    const fetchVereinData = async () => {
      if (userData && userData.vereinsId) {
        try {
          const vereinDoc = doc(db, "vereine", userData.vereinsId);
          const vereinSnap = await getDoc(vereinDoc);
          if (vereinSnap.exists()) {
            const data = vereinSnap.data();
            setLogoUrl(data.logoUrl);
            setVereinName(data.name);
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching vereine data:", error);
        }
      }
    };

    fetchVereinData();
  }, [userData]);

  const handleButtonClick = (comp) => {
    onComponentChange(comp);
  };

  return (
    <div className="settingsMainDiv">
      <div className="startHeader">
        <h1>Einstellungen</h1>
      </div>

      <div className="settingsContent" style={{ marginTop: "2rem" }}>
        <div className="settingsContentDiv">
          <div className="infoDivSettings">
            <h2>Benutzerdaten</h2>
            <p>
              <span className="fontHighlightGreen">Benutzername: </span>
              <br />
              {userData
                ? `${userData.firstName} ${userData.lastName}`
                : "loading..."}
            </p>
            <p>
              <span className="fontHighlightGreen">E-Mail: </span>
              <br />
              {userData ? userData.email : "loading..."}
            </p>
            {logoUrl && (
              <div className="logoContainer">
                <img src={logoUrl} alt="Vereinslogo" className="vereinsLogo" />
              </div>
            )}
            <p>
              <span className="fontHighlightGreen">Verein: </span>
              <br />
              {vereinName ? vereinName : "loading..."}
            </p>
          </div>
        </div>
        <div className="settingsContentDiv">
          <h2>Transaktionen</h2>
          {userData && userData.transaktionen.length === 0 && (
            <p>Keine Transaktionen vorhanden</p>
          )}
          {userData
            ? userData.transaktionen
                .slice()
                .reverse()
                .map((transaktion, index) => (
                  <div
                    className={
                      transaktion.type === "aufladen" ||
                      transaktion.type === "gewinn"
                        ? "settingsTransaktionenAufgeladen"
                        : "settingsTransaktionenMinus"
                    }
                    key={index}
                  >
                    <p>
                      Datum: {format(transaktion.date.toDate(), "dd.MM.yy")}
                    </p>
                    <p>
                      Betrag:{" "}
                      {isNaN(transaktion.betrag)
                        ? "Ungültiger Betrag"
                        : Number(transaktion.betrag).toFixed(2)}
                      €
                    </p>
                  </div>
                ))
            : "loading..."}
        </div>
      </div>
      <div className="navBar">
        <div
          className={
            activeComponent === "start"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
        >
          <div
            className="navBarIconDiv"
            onClick={() => {
              handleButtonClick("start");
            }}
          >
            <HomeIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
        <div
          className={
            activeComponent === "add"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
        >
          <div
            className="navBarIconDiv navbarHighlightDiv"
            onClick={() => handleButtonClick("add")}
          >
            <AddIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
        <div
          className={
            activeComponent === "settings"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
          onClick={() => handleButtonClick("settings")}
        >
          <div className="navBarIconDiv">
            <SettingsIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
