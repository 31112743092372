import './App.css'
import React, { useEffect } from 'react'
import Index from './components/loginOrRegister/AnmeldungsPage'
import Dashboard from './components/dashboard/Dashboard'
import AdminDashboard from './components/admin/AdminDashboard'
import { AuthProvider, useAuth } from './components/loginOrRegister/AuthContext'
import useUserData from './components/dashboard/useUserData'
import { signOut } from 'firebase/auth'
import { auth } from './firebase-config'

function AppContent () {
  const { user, loading: authLoading } = useAuth()
  const {
    userData,
    userBets,
    loading: userDataLoading,
    error
  } = useUserData(user)

  useEffect(() => {
    if (userData) {
      console.log('userRole:', userData.role)
    }
  }, [userData])

  if (authLoading) {
    return <div>Lade...</div>
  }

  // Zeige die Anmelde- oder Registrierungsseite an, wenn kein Benutzer angemeldet ist
  if (!user) {
    console.log('Kein Benutzer angemeldet')
    return <Index />
  }

  if (userDataLoading) {
    return <div>Lade...</div>
  }

  if (error && !user) {
    return (
      <div>
        <p>Fehler: {error}</p>
        <button onClick={() => signOut(auth)}>Abmelden</button>
      </div>
    )
  }

  if (user && userData) {
    if (userData.role === 'admin') {
      console.log('Admin angemeldet:', user.email)
      return <AdminDashboard />
    } else if (userData.role === 'user') {
      console.log('Benutzer angemeldet:', user.email)
      return <Dashboard />
    }
  }

  return null
}

function App () {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  )
}

export default App
