import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAbtrOOzXq7_1M9Z1RMsUKKT-GbWxDL4YU',
  authDomain: 'betterbetter2-b8501.firebaseapp.com',
  projectId: 'betterbetter2-b8501',
  storageBucket: 'betterbetter2-b8501.appspot.com',
  messagingSenderId: '590082471906',
  appId: '1:590082471906:web:026c8894bd302984c6ebbb'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export { auth, db }
