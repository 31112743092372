import React, { useEffect, useState } from "react";
import {
  doc,
  deleteDoc,
  collection,
  getDocs,
  getDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../../firebase-config";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const DeleteGameComp = () => {
  const [upcomingGames, setUpcomingGames] = useState([]);
  const [vereinId, setVereinId] = useState("");
  const [editingGame, setEditingGame] = useState(null);
  const [editHeimMannschaft, setEditHeimMannschaft] = useState("");
  const [editAuswärtsMannschaft, setEditAuswärtsMannschaft] = useState("");
  const [editDate, setEditDate] = useState("");

  useEffect(() => {
    const fetchVereinId = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = doc(db, "users", auth.currentUser.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setVereinId(userSnap.data().vereinsId);
          } else {
            console.log("Benutzerdaten nicht gefunden");
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        }
      }
    };

    fetchVereinId();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      if (vereinId) {
        try {
          const gamesCollection = collection(db, "vereine", vereinId, "games");
          const gamesSnapshot = await getDocs(gamesCollection);
          const gamesList = gamesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date.toDate
              ? doc.data().date.toDate()
              : doc.data().date,
          }));
          gamesList.sort((a, b) => a.date - b.date);
          const now = new Date();
          const upcoming = gamesList.filter((game) => game.date > now);
          setUpcomingGames(upcoming);
        } catch (error) {
          console.error("Fehler beim Abrufen der Spiele:", error);
        }
      }
    };

    fetchGames();
  }, [vereinId]);

  const handleDeleteGame = async (game) => {
    const confirmDelete = window.confirm(
      `Möchten Sie das Spiel zwischen ${game.heimMannschaft} und ${game.auswärtsMannschaft} wirklich löschen?`
    );

    if (!confirmDelete) {
      return;
    }

    try {
      // Abrufen aller Benutzer
      const usersSnapshot = await getDocs(collection(db, "users"));
      const users = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Durchlaufen aller Benutzer, um die entsprechenden Wetten zu finden und die Einsätze zurückzuerstatten
      for (const user of users) {
        const betsQuery = query(
          collection(db, "users", user.id, "bet"),
          where("gameId", "==", game.id)
        );
        const betsSnapshot = await getDocs(betsQuery);
        const bets = betsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        for (const bet of bets) {
          const updatedKontoStand = user.kontoStand + bet.einsatz;

          // Aktualisieren des Kontostands des Benutzers
          await updateDoc(doc(db, "users", user.id), {
            kontoStand: updatedKontoStand,
          });

          // Löschen der Wette des Benutzers
          await deleteDoc(doc(db, "users", user.id, "bet", bet.id));
        }
      }

      // Löschen des Spiels
      await deleteDoc(doc(db, "vereine", vereinId, "games", game.id));
      setUpcomingGames(upcomingGames.filter((g) => g.id !== game.id));
      alert("Spiel erfolgreich gelöscht und Einsätze zurückerstattet");
    } catch (error) {
      console.error("Fehler beim Löschen des Spiels:", error);
      alert("Fehler beim Löschen des Spiels. Bitte versuchen Sie es erneut.");
    }
  };

  const handleEditGame = (game) => {
    setEditingGame(game.id);
    setEditHeimMannschaft(game.heimMannschaft);
    setEditAuswärtsMannschaft(game.auswärtsMannschaft);
    const localDate = new Date(
      game.date.getTime() - game.date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);
    setEditDate(localDate);
  };

  const handleSaveEdit = async (gameId) => {
    try {
      const gameRef = doc(db, "vereine", vereinId, "games", gameId);
      await updateDoc(gameRef, {
        heimMannschaft: editHeimMannschaft,
        auswärtsMannschaft: editAuswärtsMannschaft,
        date: new Date(editDate),
      });
      setUpcomingGames(
        upcomingGames.map((game) =>
          game.id === gameId
            ? {
                ...game,
                heimMannschaft: editHeimMannschaft,
                auswärtsMannschaft: editAuswärtsMannschaft,
                date: new Date(editDate),
              }
            : game
        )
      );
      setEditingGame(null);
      alert("Spiel erfolgreich aktualisiert");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Spiels:", error);
      alert(
        "Fehler beim Aktualisieren des Spiels. Bitte versuchen Sie es erneut."
      );
    }
  };

  const handleCancelEdit = () => {
    setEditingGame(null);
  };

  return (
    <div className="adminDeleteGame">
      <div className="adminDeleteGameDiv">
        <h3 style={{ textAlign: "center" }}>Spiele:</h3>
        {upcomingGames.length === 0 && <p>Keine bevorstehenden Spiele</p>}
        {upcomingGames.map((game) => (
          <div key={game.id} className="adminDeleteGameSingleDiv">
            {editingGame === game.id ? (
              <div className="adminEditGameForm">
                <input
                  type="text"
                  value={editHeimMannschaft}
                  onChange={(e) => setEditHeimMannschaft(e.target.value)}
                  className="adminEditInput"
                />
                <input
                  type="text"
                  value={editAuswärtsMannschaft}
                  onChange={(e) => setEditAuswärtsMannschaft(e.target.value)}
                  className="adminEditInput"
                />
                <input
                  type="datetime-local"
                  value={editDate}
                  onChange={(e) => setEditDate(e.target.value)}
                  className="adminEditInput"
                />
                <button onClick={() => handleSaveEdit(game.id)}>
                  <SaveIcon />
                </button>
                <button onClick={handleCancelEdit}>
                  <CancelIcon />
                </button>
              </div>
            ) : (
              <div className="adminGameInfo">
                <p>
                  {game.heimMannschaft} : {game.auswärtsMannschaft}
                </p>
                <p>{game.date.toLocaleString("de-DE")}</p>
                <div className="adminGameActions">
                  <button
                    style={{ backgroundColor: "#007bff" }}
                    onClick={() => handleEditGame(game)}
                  >
                    <EditIcon />
                  </button>
                  <button
                    style={{ backgroundColor: "red" }}
                    onClick={() => handleDeleteGame(game)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeleteGameComp;
