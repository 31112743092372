import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chart.js/auto";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const IncomeChart = ({ pastGames }) => {
  console.log(pastGames);
  const data = {
    labels: pastGames.map((game) =>
      game.date instanceof Date
        ? game.date.toLocaleDateString("de-DE")
        : new Date(game.date.seconds * 1000).toLocaleDateString("de-DE")
    ),
    datasets: [
      {
        label: "Einnahmen",
        data: pastGames.map((game) => game.effectiveIncome || 0),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h3>Einnahmen der letzten Wochen</h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default IncomeChart;
