import React, { useState, useRef, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "../../firebase-config";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { ReactComponent as Logo } from "../resources/logoBetterBetSVG1.svg";
import "./style/Register.css";

const Register = () => {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerFirstName, setRegisterFirstName] = useState("");
  const [registerLastName, setRegisterLastName] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [user, setUser] = useState(null);
  const [vereine, setVereine] = useState([]);
  const [selectedVerein, setSelectedVerein] = useState("");
  const firstNameInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    if (currentStep === 1 && firstNameInputRef.current) {
      firstNameInputRef.current.focus();
    }

    return () => unsubscribe();
  }, [currentStep]);

  useEffect(() => {
    const fetchVereine = async () => {
      try {
        const vereineCollection = collection(db, "vereine");
        const vereineSnapshot = await getDocs(vereineCollection);
        const vereineList = vereineSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVereine(vereineList);
      } catch (error) {
        console.error("Fehler beim Abrufen der Vereine:", error);
      }
    };

    fetchVereine();
  }, []);

  const register = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );

      await setDoc(doc(db, "users", userCredential.user.uid), {
        firstName: registerFirstName,
        lastName: registerLastName,
        email: registerEmail,
        kontoStand: 0,
        transaktionen: [],
        role: "user",
        vereinsId: null, // Setze die vereinsId auf null, bis die Beitrittsanfrage genehmigt ist
      });

      await addDoc(collection(db, "vereine", selectedVerein, "requests"), {
        userId: userCredential.user.uid,
        date: Timestamp.now(),
      });

      console.log(userCredential.user);
      alert(
        userCredential.user.email +
          " wurde registriert. Beitrittsanfrage gesendet."
      );
    } catch (error) {
      console.error(error.message);
      if (error.code === "auth/email-already-in-use") {
        alert("Email bereits in Verwendung");
      } else {
        alert("Registrierung fehlgeschlagen: " + error.message);
      }
    }
  };

  const validateStep = () => {
    if (currentStep === 1 && registerFirstName.length < 2) {
      alert("Vorname muss mindestens 2 Zeichen lang sein");
      return false;
    } else if (currentStep === 2 && registerLastName.length < 2) {
      alert("Nachname muss mindestens 2 Zeichen lang sein");
      return false;
    } else if (currentStep === 3 && !registerEmail.includes("@")) {
      alert("Email ist nicht gültig");
      return false;
    } else if (currentStep === 4 && registerPassword.length < 6) {
      alert("Passwort muss mindestens 6 Zeichen lang sein");
      return false;
    } else if (currentStep === 5 && selectedVerein === "") {
      alert("Bitte wählen Sie einen Verein aus");
      return false;
    }
    return true;
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateStep()) {
      register();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (currentStep === 5) {
        handleSubmit(event);
      } else {
        handleNextStep();
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <label htmlFor="firstNameInput">Vorname</label>
            <input
              ref={firstNameInputRef}
              id="firstNameInput"
              placeholder="Vorname..."
              value={registerFirstName}
              onChange={(e) => setRegisterFirstName(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button className="apButton" type="button" onClick={handleNextStep}>
              Weiter
            </button>
          </>
        );
      case 2:
        return (
          <>
            <label htmlFor="lastNameInput">Nachname</label>
            <input
              id="lastNameInput"
              placeholder="Nachname..."
              value={registerLastName}
              onChange={(e) => setRegisterLastName(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="submitButton"
              type="button"
              onClick={handlePrevStep}
            >
              Zurück
            </button>
            <button className="apButton" type="button" onClick={handleNextStep}>
              Weiter
            </button>
          </>
        );
      case 3:
        return (
          <>
            <label htmlFor="emailInput">Email</label>
            <input
              id="emailInput"
              placeholder="Email..."
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="submitButton"
              type="button"
              onClick={handlePrevStep}
            >
              Zurück
            </button>
            <button className="apButton" type="button" onClick={handleNextStep}>
              Weiter
            </button>
          </>
        );
      case 4:
        return (
          <>
            <label htmlFor="passwordInput">Passwort</label>
            <input
              id="passwordInput"
              type="password"
              placeholder="Passwort..."
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="submitButton"
              type="button"
              onClick={handlePrevStep}
            >
              Zurück
            </button>
            <button className="apButton" type="button" onClick={handleNextStep}>
              Weiter
            </button>
          </>
        );
      case 5:
        return (
          <>
            <label htmlFor="vereinSelect">Verein auswählen</label>
            <select
              id="vereinSelect"
              value={selectedVerein}
              onChange={(e) => setSelectedVerein(e.target.value)}
              onKeyPress={handleKeyPress}
            >
              <option value="" disabled>
                Verein auswählen...
              </option>
              {vereine.map((verein) => (
                <option key={verein.id} value={verein.id}>
                  {verein.name}
                </option>
              ))}
            </select>
            <button
              className="submitButton"
              type="button"
              onClick={handlePrevStep}
            >
              Zurück
            </button>
            <button className="apHighlightButton apButton" type="submit">
              Registrieren
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="width100height100flex">
      <div className="lginMainDiv">
        <h3 className="lginHeading">
          Benutzer <span className="highlightFont">Registrieren</span>
        </h3>
        <form onSubmit={handleSubmit}>{renderStep()}</form>
      </div>
      <div className="halfDivAP">
        <Logo width="350" height="auto" /> <p>exSolution®</p>
      </div>
    </div>
  );
};

export default Register;
