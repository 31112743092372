import { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { auth } from "../../firebase-config";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

const useGames = () => {
  const [upcomingGames, setUpcomingGames] = useState([]);
  const [pastGames, setPastGames] = useState([]);
  const [vereinId, setVereinId] = useState([]);

  useEffect(() => {
    const fetchVereinId = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = doc(db, "users", auth.currentUser.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setVereinId(userSnap.data().vereinsId);
          } else {
            console.log("Benutzerdaten nicht gefunden");
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        }
      }
    };

    fetchVereinId();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      if (vereinId) {
        try {
          const gamesCollection = collection(db, "vereine", vereinId, "games");
          const gamesSnapshot = await getDocs(gamesCollection);
          const gamesList = gamesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          gamesList.sort((a, b) => a.date.toDate() - b.date.toDate());
          const now = new Date();
          const past = gamesList.filter((game) => game.date.toDate() <= now);
          const future = gamesList.filter((game) => game.date.toDate() >= now);
          setPastGames(past);
          setUpcomingGames(future);
          console.log(future);
          console.log(past);
        } catch (error) {
          console.error("Fehler beim Abrufen der Spiele:", error);
        }
      }
    };

    fetchGames();
  }, [vereinId]);

  return { upcomingGames, pastGames };
};

export default useGames;
