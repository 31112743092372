import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import Start from "./Start";
import Add from "./Add";
import Settings from "./Settings";
import "./style/Dashboard.css";

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("start");
  const [gameIdForAddComponent, setGameIdForAddComponent] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleComponentChange = (componentName, gameId) => {
    setActiveComponent(componentName);
    if (gameId) setGameIdForAddComponent(gameId);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const userSnap = await getDoc(userDoc);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Lade Benutzerdaten...</div>;
  }

  if (!userData || !userData.vereinsId) {
    return (
      <div className="noAccess">
        <p>
          Sie wurden noch keinem Verein zugewiesen. Bitte warten Sie, bis Ihre
          Anfrage akzeptiert wird.
        </p>
      </div>
    );
  }

  return (
    <div className="Dashboard">
      {activeComponent === "start" && (
        <Start
          onComponentChange={handleComponentChange}
          activeComponent={activeComponent}
        />
      )}
      {activeComponent === "add" && (
        <Add
          onComponentChange={handleComponentChange}
          activeComponent={activeComponent}
          standartGameId={gameIdForAddComponent}
        />
      )}
      {activeComponent === "settings" && (
        <Settings
          onComponentChange={handleComponentChange}
          activeComponent={activeComponent}
        />
      )}
    </div>
  );
};

export default Dashboard;
