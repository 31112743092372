import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase-config";
import {
  addDoc,
  getDoc,
  getDocs,
  doc,
  setDoc,
  collection,
} from "firebase/firestore";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { format } from "date-fns";
import useUserData from "./useUserData";
import useGames from "./useGames";
import "./style/Add.css";

const Add = ({ onComponentChange, activeComponent }) => {
  const { userData, userBets, updateUserData } = useUserData();
  const { upcomingGames } = useGames();
  const [formData, setFormData] = useState({
    einsatz: "",
    gameId: "",
    ergebnis: [],
  });

  useEffect(() => {
    if (upcomingGames.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gameId: upcomingGames[0].id,
      }));
    }
  }, [upcomingGames]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name.startsWith("ergebnis")) {
      const updatedErgebnis = [...formData.ergebnis];
      const fieldIndex = parseInt(name.split("-")[1]);

      let formattedValue = value;

      // Füge den Doppelpunkt nach der ersten Zahl hinzu, falls nicht bereits vorhanden
      if (value.length === 1 && value !== ":") {
        formattedValue = value + ":";
      } else if (value.length === 2 && value[1] !== ":") {
        formattedValue = value[0] + ":" + value[1];
      } else if (value.length > 3) {
        formattedValue = value.slice(0, 3);
      }

      updatedErgebnis[fieldIndex] = formattedValue;
      setFormData({ ...formData, ergebnis: updatedErgebnis });
    } else if (name === "einsatz") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const addBet = async (betData) => {
    const user = auth.currentUser;

    if (!user) {
      console.error("Benutzer nicht angemeldet");
      return;
    }

    const userId = user.uid;

    try {
      await addDoc(collection(db, "users", userId, "bet"), betData);
    } catch (error) {
      console.error("Fehler beim Platzieren der Wette:", error);
      alert(
        "Leider ist ein Fehler aufgetreten (" +
          error.message +
          "). Bitte versuchen Sie es erneut."
      );
    }
  };

  const checkBets = (bets) => {
    const ergebnisse = bets.ergebnis;

    const uniqueResults = new Set();

    for (const result of ergebnisse) {
      if (uniqueResults.has(result)) {
        return true;
      }
      uniqueResults.add(result);
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      console.error("Benutzer nicht angemeldet");
      return;
    }

    const einsatzValue = parseFloat(formData.einsatz);
    if (userData.kontoStand < einsatzValue) {
      alert("kontoStand zu niedrig");
      return;
    }

    if (checkBets(formData)) {
      alert("Sie können kein Ergebnis zwei mal tippen");
      return;
    }

    const updatedKontoStand = userData.kontoStand - einsatzValue;
    const updatedTransaktionen = userData.transaktionen.concat({
      betrag: einsatzValue,
      date: new Date(),
      game: formData.gameId,
    });

    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      await setDoc(
        userDocRef,
        {
          kontoStand: updatedKontoStand,
          transaktionen: updatedTransaktionen,
        },
        { merge: true }
      );

      await addBet({ ...formData, einsatz: einsatzValue });

      alert("Wette erfolgreich platziert!");

      updateUserData({
        ...userData,
        kontoStand: updatedKontoStand,
        transaktionen: updatedTransaktionen,
      });

      onComponentChange("start");
    } catch (error) {
      console.error("Fehler beim Platzieren der Wette:", error);
      alert(
        "Leider ist ein Fehler aufgetreten (" +
          error.message +
          "). Bitte versuchen Sie es erneut."
      );
    }
  };

  const handleButtonClick = (comp) => {
    onComponentChange(comp);
  };

  const getUnbettedGames = () => {
    const unbettedGames = upcomingGames.filter((game) => {
      const betsForGame = userBets.filter((bet) => bet.gameId === game.id);
      return betsForGame.length === 0;
    });
    return unbettedGames;
  };

  const [gamesToBetOn, setGamesToBetOn] = useState([]);

  useEffect(() => {
    const unbettedGames = getUnbettedGames();
    setGamesToBetOn(unbettedGames);

    if (unbettedGames.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gameId: unbettedGames[0].id,
      }));
    }
  }, [upcomingGames, userBets]);

  return (
    <div className="Add">
      <div className="startHeader">
        <h1>Platzieren Sie Ihre Wette!</h1>
      </div>
      {gamesToBetOn.length === 0 ? (
        <p>Es sind keine Spiele verfügbar, auf die Sie wetten können.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="game">Spiel</label>
            <select
              id="game"
              name="gameId"
              onChange={handleChange}
              value={formData.gameId}
            >
              {gamesToBetOn.map((game) => (
                <option key={game.id} value={game.id}>
                  {game.heimMannschaft +
                    " : " +
                    game.auswärtsMannschaft +
                    "     (" +
                    format(game.date.toDate(), "dd.MM.yy") +
                    ")"}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="einsatz">Wie viel Wetten möchten Sie setzen?</label>
            <input
              placeholder={
                userData && userData.kontoStand !== null
                  ? (userData.kontoStand <= 30
                      ? userData.kontoStand
                      : "max 30") + "€"
                  : "Kein Geld vorhanden!"
              }
              type="number"
              id="einsatz"
              name="einsatz"
              value={formData.einsatz}
              onChange={handleChange}
              required
              max={30}
              min={1}
            />
          </div>

          {formData.einsatz > 0 &&
            Array.from({ length: parseFloat(formData.einsatz) }).map(
              (_, index) => (
                <div className="form-group" key={index}>
                  <label htmlFor={`ergebnis-${index}`}>
                    Ergebnis {index + 1}
                  </label>
                  <input
                    placeholder="- : -"
                    type="text"
                    id={`ergebnis-${index}`}
                    name={`ergebnis-${index}`}
                    value={formData.ergebnis[index] || ""}
                    onChange={(e) => handleChange(e, index)}
                    required
                    maxLength={3}
                  />
                </div>
              )
            )}

          <button type="submit">Wette platzieren</button>
        </form>
      )}

      <div className="navBar">
        <div
          className={
            activeComponent === "start"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
        >
          <div
            className="navBarIconDiv"
            onClick={() => {
              handleButtonClick("start");
            }}
          >
            <HomeIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
        <div
          className={
            activeComponent === "add"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
        >
          <div
            className="navBarIconDiv navbarHighlightDiv"
            onClick={() => handleButtonClick("add")}
          >
            <AddIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
        <div
          className={
            activeComponent === "settings"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
          onClick={() => handleButtonClick("settings")}
        >
          <div className="navBarIconDiv">
            <SettingsIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
