import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  getDocs,
  getDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../../firebase-config";
import AddIcon from "@mui/icons-material/Add";
import ExpandedGame from "./ExpandedGame";
import IncomeChart from "./helpers/IncomeChart"; // Import the IncomeChart component
import fetchWinnersAndUpdateBalances from "./fetchWinnersAndUpdateBalances"; // Import the function
import "./style/AdminGamesSection.css";

const MoneyManagementComp = () => {
  const [currentGameInAddGame, setCurrentGameInAddGame] = useState(null);
  const [ergebnis, setErgebnis] = useState("");
  const [expandedGame, setExpandedGame] = useState(null);
  const [pastGames, setPastGames] = useState([]);
  const [pastGamesNoResult, setPastGamesNoResult] = useState([]);
  const [vereinId, setVereinId] = useState("");

  useEffect(() => {
    const fetchVereinId = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = doc(db, "users", auth.currentUser.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setVereinId(userSnap.data().vereinsId);
          } else {
            console.log("Benutzerdaten nicht gefunden");
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        }
      }
    };

    fetchVereinId();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      if (vereinId) {
        try {
          const gamesCollection = collection(db, "vereine", vereinId, "games");
          const gamesSnapshot = await getDocs(gamesCollection);
          const gamesList = gamesSnapshot.docs.map((doc) => {
            const data = doc.data();
            console.log("Fetched game data:", data); // Konsolenausgabe der Spieldaten
            return {
              id: doc.id,
              ...data,
              date: data.date.toDate(), // Konvertieren des Timestamp-Feldes in ein Date-Objekt
            };
          });
          console.log("Games list before filtering:", gamesList); // Log games list before filtering

          const now = new Date();
          const past = gamesList.filter(
            (game) => game.date && game.date <= now
          );
          console.log("Filtered past games:", past); // Log filtered past games

          setPastGames(past);
          const gamesWithNoResult = past.filter((game) => !game.ergebnis);
          setPastGamesNoResult(gamesWithNoResult);
        } catch (error) {
          console.error("Fehler beim Abrufen der Spiele:", error);
        }
      }
    };

    fetchGames();
  }, [vereinId]);

  const handleAddResultClick = (gameId) => {
    setCurrentGameInAddGame(gameId);
  };

  const handleExpandGameClick = (gameId) => {
    setExpandedGame(gameId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await fetchWinnersAndUpdateBalances(
      vereinId,
      currentGameInAddGame,
      ergebnis
    );
    setCurrentGameInAddGame(null);
    setErgebnis("");
  };

  const handleErgebnisChange = (e) => {
    let { value } = e.target;

    // Füge den Doppelpunkt nach der ersten Zahl hinzu, falls nicht bereits vorhanden
    if (value.length === 1 && value !== ":") {
      value = value + ":";
    } else if (value.length === 2 && value[1] !== ":") {
      value = value[0] + ":" + value[1];
    } else if (value.length > 3) {
      value = value.slice(0, 3);
    }

    setErgebnis(value);
  };

  return (
    <div className="ergebnisEintragenDiv">
      {pastGames.length > 0 && <IncomeChart pastGames={pastGames} />}
      <div className="ergebnisEintragenContainer">
        {pastGamesNoResult.length !== 0 && <h3>Einzutragene Spiele:</h3>}
        {pastGamesNoResult.map((game) => (
          <div key={game.id} className="adminErgbenisEintragen">
            <p>
              {game.heimMannschaft} : {game.auswärtsMannschaft}
            </p>
            <p>{game.date.toLocaleString("de-DE")}</p>
            <div
              className="settingsAddIconDiv"
              onClick={() => handleAddResultClick(game.id)}
            >
              <AddIcon />
            </div>
            {currentGameInAddGame === game.id && (
              <div className="ergebnisEintragenForm">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="ergebnis">Ergebnis:</label>
                  <input
                    type="text"
                    id="ergebnis"
                    name="ergebnis"
                    required
                    value={ergebnis}
                    placeholder="z.B. 2:1"
                    onChange={handleErgebnisChange}
                  />
                  <input type="submit" value="Ergebnis eintragen" />
                </form>
              </div>
            )}
          </div>
        ))}
        <h3>Letzte Spiele:</h3>
        {pastGames.length === 0 && <p>Keine Spiele gefunden</p>}
        {pastGames
          .sort((a, b) => b.date - a.date)
          .map((game) => (
            <div key={game.id} className="adminErgbenisEintragen">
              <p
                onClick={() => handleExpandGameClick(game.id)}
                style={{ cursor: "pointer" }}
              >
                {game.heimMannschaft} : {game.auswärtsMannschaft}
              </p>
              <p>{game.date.toLocaleDateString("de-DE")}</p>
              <p>Ergebnis: {game.ergebnis || "Noch nicht eingetragen"}</p>
              {expandedGame === game.id && (
                <ExpandedGame
                  gameId={game.id}
                  onClose={() => setExpandedGame(null)}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MoneyManagementComp;
