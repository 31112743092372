import React, { useState, useEffect } from "react";
import {
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
  getDoc,
} from "firebase/firestore";
import "./style/JoinRequests.css";
import { db } from "../../../firebase-config";

const JoinRequests = () => {
  const [vereine, setVereine] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchVereine = async () => {
      try {
        const vereineCollection = collection(db, "vereine");
        const vereineSnapshot = await getDocs(vereineCollection);
        const vereineList = vereineSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVereine(vereineList);
      } catch (error) {
        console.error("Fehler beim Abrufen der Vereine:", error);
      }
    };

    fetchVereine();
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const allRequests = [];
        for (const verein of vereine) {
          const requestsCollection = collection(
            db,
            "vereine",
            verein.id,
            "requests"
          );
          const requestsSnapshot = await getDocs(requestsCollection);
          const requestsList = requestsSnapshot.docs.map((doc) => ({
            id: doc.id,
            vereinId: verein.id,
            ...doc.data(),
          }));

          for (const request of requestsList) {
            const userDoc = await getDoc(doc(db, "users", request.userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              request.userName = `${userData.firstName} ${userData.lastName}`;
            } else {
              request.userName = "Unbekannt";
            }
          }

          allRequests.push(...requestsList);
        }
        setRequests(allRequests);
      } catch (error) {
        console.error("Fehler beim Abrufen der Beitrittsanfragen:", error);
      }
    };

    if (vereine.length > 0) {
      fetchRequests();
    }
  }, [vereine]);

  const handleAccept = async (request) => {
    try {
      const userRef = doc(db, "users", request.userId);
      await updateDoc(userRef, {
        vereinsId: request.vereinId,
      });

      await deleteDoc(
        doc(db, "vereine", request.vereinId, "requests", request.id)
      );
      setRequests(requests.filter((req) => req.id !== request.id));
    } catch (error) {
      console.error("Fehler beim Akzeptieren der Anfrage:", error);
    }
  };

  const handleReject = async (request) => {
    try {
      await deleteDoc(
        doc(db, "vereine", request.vereinId, "requests", request.id)
      );
      setRequests(requests.filter((req) => req.id !== request.id));
    } catch (error) {
      console.error("Fehler beim Ablehnen der Anfrage:", error);
    }
  };

  return (
    <div className="joinRequestsContainer">
      <h1>Join Requests</h1>
      {requests.length > 0 ? (
        requests.map((request) => (
          <div key={request.id} className="requestItem">
            <p>
              <span className="requestLabel">User:</span> {request.userName}
            </p>
            <p>
              <span className="requestLabel">Date:</span>{" "}
              {new Date(request.date.toDate()).toLocaleString()}
            </p>
            <button
              className="acceptButton"
              onClick={() => handleAccept(request)}
              style={{ marginRight: "0" }}
            >
              Akzeptieren
            </button>
            <button
              className="rejectButton"
              onClick={() => handleReject(request)}
            >
              Ablehnen
            </button>
          </div>
        ))
      ) : (
        <p>Keine Beitrittsanfragen vorhanden</p>
      )}
    </div>
  );
};

export default JoinRequests;
