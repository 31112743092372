import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  query,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase-config";
import { auth } from "../../../firebase-config";
import "./style/BetForPlayer.css";
import { format } from "date-fns";

const BetForPlayer = ({ userId, onClose }) => {
  const [adminVereinsId, setAdminVereinsId] = useState("");
  const [bets, setBets] = useState([]);
  const [games, setGames] = useState([]);
  const [gameDetails, setGameDetails] = useState({});
  const [formData, setFormData] = useState({
    einsatz: "",
    gameId: "",
    ergebnis: [],
  });

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const betsQuery = query(collection(db, "users", userId, "bet"));
        const betsSnapshot = await getDocs(betsQuery);
        const betsList = betsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sortiere die Wetten nach Datum und zeige nur die letzten 5 Wetten an
        betsList.sort((a, b) => {
          if (a.date && b.date) {
            return b.date.toDate() - a.date.toDate();
          } else {
            return 0;
          }
        });
        setBets(betsList.slice(0, 5));
      } catch (error) {
        console.error("Fehler beim Abrufen der Wetten:", error);
      }
    };

    fetchBets();
  }, [userId]);

  useEffect(() => {
    const fetchGames = async () => {
      if (adminVereinsId) {
        try {
          const gamesCollection = collection(
            db,
            "vereine",
            adminVereinsId,
            "games"
          );
          const gamesSnapshot = await getDocs(gamesCollection);
          const gamesList = gamesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          gamesList.sort((a, b) => {
            if (a.date && b.date) {
              return a.date.toDate() - b.date.toDate();
            } else {
              return 0;
            }
          });
          const now = new Date();
          const future = gamesList.filter(
            (game) => game.date && game.date.toDate() >= now
          );
          setGames(future);
        } catch (error) {
          console.error("Fehler beim Abrufen der Spiele:", error);
        }
      }
    };

    fetchGames();
  }, [adminVereinsId]);

  useEffect(() => {
    const fetchAdminVereinsId = async () => {
      if (auth.currentUser) {
        try {
          const adminDoc = doc(db, "users", auth.currentUser.uid);
          const adminSnap = await getDoc(adminDoc);
          if (adminSnap.exists()) {
            setAdminVereinsId(adminSnap.data().vereinsId);
          } else {
            console.log("Admin-Daten nicht gefunden");
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Admin-Daten:", error);
        }
      } else {
        console.log("Benutzer nicht angemeldet");
      }
    };

    fetchAdminVereinsId();
  }, []);

  useEffect(() => {
    if (games.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gameId: games[0].id,
      }));
    }
  }, [games]);

  useEffect(() => {
    const fetchGameDetails = async () => {
      const details = {};
      for (const bet of bets) {
        const gameDoc = await getDoc(
          doc(db, "vereine", adminVereinsId, "games", bet.gameId)
        );
        if (gameDoc.exists()) {
          const gameData = gameDoc.data();
          details[
            bet.gameId
          ] = `${gameData.heimMannschaft} : ${gameData.auswärtsMannschaft}`;
        } else {
          details[bet.gameId] = "Spiel nicht gefunden";
        }
      }
      setGameDetails(details);
    };

    if (adminVereinsId && bets.length > 0) {
      fetchGameDetails();
    }
  }, [bets, adminVereinsId]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name.startsWith("ergebnis")) {
      const updatedErgebnis = [...formData.ergebnis];
      const fieldIndex = parseInt(name.split("-")[1]);

      let formattedValue = value;

      // Füge den Doppelpunkt nach der ersten Zahl hinzu, falls nicht bereits vorhanden
      if (value.length === 1 && value !== ":") {
        formattedValue = value + ":";
      } else if (value.length === 2 && value[1] !== ":") {
        formattedValue = value[0] + ":" + value[1];
      } else if (value.length > 3) {
        formattedValue = value.slice(0, 3);
      }

      updatedErgebnis[fieldIndex] = formattedValue;
      setFormData({ ...formData, ergebnis: updatedErgebnis });
    } else if (name === "einsatz") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const addBet = async (betData) => {
    try {
      await addDoc(collection(db, "users", userId, "bet"), betData);
    } catch (error) {
      console.error("Fehler beim Platzieren der Wette:", error);
      alert(
        "Leider ist ein Fehler aufgetreten (" +
          error.message +
          "). Bitte versuchen Sie es erneut."
      );
    }
  };

  const checkBets = (bets) => {
    const ergebnisse = bets.ergebnis;

    const uniqueResults = new Set();

    for (const result of ergebnisse) {
      if (uniqueResults.has(result)) {
        return true;
      }
      uniqueResults.add(result);
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const einsatzValue = parseFloat(formData.einsatz);

    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();

    if (userData.kontoStand < einsatzValue) {
      alert("kontoStand zu niedrig");
      return;
    }

    if (checkBets(formData)) {
      alert("Sie können kein Ergebnis zwei mal tippen");
      return;
    }

    const updatedKontoStand = userData.kontoStand - einsatzValue;
    const updatedTransaktionen = userData.transaktionen.concat({
      betrag: einsatzValue,
      date: new Date(),
      game: formData.gameId,
    });

    try {
      await updateDoc(userDocRef, {
        kontoStand: updatedKontoStand,
        transaktionen: updatedTransaktionen,
      });

      await addBet({ ...formData, einsatz: einsatzValue });

      alert("Wette erfolgreich platziert!");

      setFormData({
        einsatz: "",
        gameId: games[0]?.id || "",
        ergebnis: [],
      });

      // Aktualisieren der Wetten
      const betsQuery = query(collection(db, "users", userId, "bet"));
      const betsSnapshot = await getDocs(betsQuery);
      const betsList = betsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      betsList.sort((a, b) => {
        if (a.date && b.date) {
          return b.date.toDate() - a.date.toDate();
        } else {
          return 0;
        }
      });
      setBets(betsList.slice(0, 5));
    } catch (error) {
      console.error("Fehler beim Platzieren der Wette:", error);
      alert(
        "Leider ist ein Fehler aufgetreten (" +
          error.message +
          "). Bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <div className="betForPlayer">
      <h3>Wetten für Benutzer</h3>
      <button onClick={onClose}>Schließen</button>

      <h4>Neue Wette hinzufügen</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="game">Spiel</label>
          <select
            id="game"
            name="gameId"
            onChange={handleChange}
            value={formData.gameId}
          >
            {games.map((game) => (
              <option key={game.id} value={game.id}>
                {game.heimMannschaft +
                  " : " +
                  game.auswärtsMannschaft +
                  " (" +
                  format(game.date.toDate(), "dd.MM.yy") +
                  ")"}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="einsatz">Wie viel Wetten möchten Sie setzen?</label>
          <input
            type="number"
            id="einsatz"
            name="einsatz"
            value={formData.einsatz}
            onChange={handleChange}
            required
            min={1}
          />
        </div>
        {formData.einsatz > 0 &&
          Array.from({ length: parseFloat(formData.einsatz) }).map(
            (_, index) => (
              <div className="form-group" key={index}>
                <label htmlFor={`ergebnis-${index}`}>
                  Ergebnis {index + 1}
                </label>
                <input
                  type="text"
                  id={`ergebnis-${index}`}
                  name={`ergebnis-${index}`}
                  value={formData.ergebnis[index] || ""}
                  onChange={(e) => handleChange(e, index)}
                  required
                  maxLength={3}
                />
              </div>
            )
          )}
        <button type="submit">Wette platzieren</button>
      </form>
    </div>
  );
};

export default BetForPlayer;
