import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Logo } from "../resources/logoBetterBetSVG1.svg";
import "./style/VereinAnmelden.css";

const VereinAnmelden = () => {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [vereinName, setVereinName] = useState(""); // Neues Feld
  const [firstName, setFirstName] = useState(""); // Neues Feld
  const [lastName, setLastName] = useState(""); // Neues Feld
  const [currentStep, setCurrentStep] = useState(1); // Schrittverwaltung
  const vereinNameInputRef = useRef(null); // Ref für den ersten Schritt

  useEffect(() => {
    // Autofokus auf den ersten Schritt setzen
    if (currentStep === 1 && vereinNameInputRef.current) {
      vereinNameInputRef.current.focus();
    }
  }, [currentStep]);

  const register = async () => {
    // Hier logik hinzufügen, um den Verein anzumelden
    console.log(
      "Verein anmelden:",
      vereinName,
      firstName,
      lastName,
      registerEmail,
      registerPassword
    );
    alert("Verein wurde erfolgreich angemeldet");
  };

  const validateStep = (step) => {
    if (step === 2 && vereinName.length < 2) {
      alert("Vereinsname muss mindestens 2 Zeichen lang sein");
      return false;
    } else if (step === 3 && (firstName.length < 2 || lastName.length < 2)) {
      alert("Vorname und Nachname müssen mindestens 2 Zeichen lang sein");
      return false;
    } else if (step === 4 && !registerEmail.includes("@")) {
      alert("Email ist nicht gültig");
      return false;
    } else if (step === 5 && registerPassword.length < 6) {
      alert("Passwort muss mindestens 6 Zeichen lang sein");
      return false;
    }
    return true;
  };

  const handleNextStep = () => {
    if (validateStep(currentStep + 1)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (registerPassword !== confirmPassword) {
      console.log("Passwörter stimmen nicht überein");
      return;
    }
    register();
  };

  // Geänderte renderStep Funktion, nur der Text wird angezeigt
  const renderStep = () => {
    return (
      <p>Melden Sie sich bei dieser Email Adresse xaver.ebetshuber@gmail.com</p>
    );
  };

  return (
    <div className="width100height100flex">
      <div className="contentVereinAnmelden">
        <h3 className="lginHeading">
          Verein <span className="highlightFont">Anmelden</span>
        </h3>
        <form onSubmit={handleSubmit}>{renderStep()}</form>
      </div>
      <div className="halfDivAP">
        <Logo width="350" height="auto" /> <p>exSolution®</p>
      </div>
    </div>
  );
};

export default VereinAnmelden;
