import React, { useState, useEffect } from "react";
import { addDoc, collection, Timestamp, doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase-config";

const AddComp = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [vereinId, setVereinId] = useState("");

  useEffect(() => {
    const fetchVereinId = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = doc(db, "users", auth.currentUser.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setVereinId(userSnap.data().vereinsId);
          } else {
            console.log("Benutzerdaten nicht gefunden");
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        }
      }
    };

    fetchVereinId();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const heimMannschaft = e.target.heimMannschaft.value;
    const gastMannschaft = e.target.gastMannschaft.value;
    const spielDatum = e.target.spielDatum.value;
    const spielUhrzeit = e.target.spielUhrzeit.value;

    const spielDateTime = new Date(`${spielDatum}T${spielUhrzeit}`);

    const now = new Date();
    if (spielDateTime <= now) {
      setErrorMessage(
        "Das Datum und die Uhrzeit müssen in der Zukunft liegen."
      );
      return;
    }

    if (!vereinId) {
      setErrorMessage("Vereins-ID konnte nicht abgerufen werden.");
      return;
    }

    try {
      await addDoc(collection(db, "vereine", vereinId, "games"), {
        heimMannschaft,
        auswärtsMannschaft: gastMannschaft,
        date: Timestamp.fromDate(spielDateTime),
        ergebnis: "",
      });
      setSuccessMessage("Spiel erfolgreich hinzugefügt!");
      setErrorMessage("");
      e.target.reset();
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Spiels:", error);
      setErrorMessage(
        "Fehler beim Hinzufügen des Spiels. Bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <div className="adminAddComp">
      <div className="adminAddDiv">
        <form onSubmit={handleSubmit}>
          <label htmlFor="heimMannschaft">Heimmannschaft:</label>
          <input
            required
            type="text"
            id="heimMannschaft"
            name="heimMannschaft"
          />
          <label htmlFor="gastMannschaft">Gastmannschaft:</label>
          <input
            required
            type="text"
            id="gastMannschaft"
            name="gastMannschaft"
          />
          <label htmlFor="spielDatum">Spiel Datum:</label>
          <input required type="date" id="spielDatum" name="spielDatum" />
          <label htmlFor="spielUhrzeit">Spiel Uhrzeit:</label>
          <input required type="time" id="spielUhrzeit" name="spielUhrzeit" />
          <input
            className="adminSubmitButton"
            type="submit"
            value="Spiel hinzufügen"
          />
        </form>
        {successMessage && <p className="successMessage">{successMessage}</p>}
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default AddComp;
