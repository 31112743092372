import React, { useEffect, useState } from "react";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db, auth } from "../../firebase-config";
import "./style/ExpandedGame.css";

const ExpandedGame = ({ gameId, onClose }) => {
  const [gameData, setGameData] = useState(null);
  const [vereinId, setVereinId] = useState("");
  const [userNames, setUserNames] = useState({});

  useEffect(() => {
    const fetchVereinId = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = doc(db, "users", auth.currentUser.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setVereinId(userSnap.data().vereinsId);
          } else {
            console.log("Benutzerdaten nicht gefunden");
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        }
      }
    };

    fetchVereinId();
  }, []);

  useEffect(() => {
    const fetchGameDetails = async () => {
      if (vereinId) {
        try {
          const gameRef = doc(db, "vereine", vereinId, "games", gameId);
          const gameSnap = await getDoc(gameRef);
          if (gameSnap.exists()) {
            setGameData(gameSnap.data());
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Spieldaten:", error);
        }
      }
    };

    fetchGameDetails();
  }, [gameId, vereinId]);

  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const users = {};
        usersSnapshot.forEach((doc) => {
          users[doc.id] = `${doc.data().firstName} ${doc.data().lastName}`;
        });
        setUserNames(users);
      } catch (error) {
        console.error("Fehler beim Abrufen der Benutzernamen:", error);
      }
    };

    fetchUserNames();
  }, []);

  return (
    <div className="expandedGameContainer">
      <button className="closeButton" onClick={onClose}>
        X
      </button>
      {gameData ? (
        <>
          <h2>
            {gameData.heimMannschaft} : {gameData.auswärtsMannschaft}
          </h2>
          <p>Datum: {gameData.date.toDate().toLocaleString("de-DE")}</p>
          <p>Ergebnis: {gameData.ergebnis}</p>
          <p>Gesamteinsatz: {gameData.totalIncome}€</p>
          <p>Effektiver Gewinn: {gameData.effectiveIncome}€</p>
          <p>Gewinnbetrag pro Gewinner: {gameData.moneyPerWinner}€</p>
          <p>Gewinner:</p>
          <ul>
            {gameData.winners.map((winnerId) => (
              <li key={winnerId}>{userNames[winnerId] || winnerId}</li>
            ))}
          </ul>
        </>
      ) : (
        <p>Lade Spielinformationen...</p>
      )}
    </div>
  );
};

export default ExpandedGame;
