import React, { useState } from "react";
import Login from "./Login";
import VereinAnmelden from "./VereinAnmelden";
import Register from "./Register";
import "./style/AnmeldungsPage.css";
import bgImg from "../resources/bgImg.png";
import { ReactComponent as Logo } from "../resources/logoBetterBetSVG1.svg";

const Index = () => {
  const [loginOrRegister, setLoginOrRegister] = useState("");

  const handleClicked = (view) => {
    setLoginOrRegister(view);
  };

  return (
    <div className="apMainDiv">
      {loginOrRegister === "" ? (
        <div className="landingPageMainDiv">
          <div className="imgDiv">
            <img src={bgImg} alt="Background" />
          </div>
          <div className="landingPageContent">
            <div className="headingDivLP">
              <h1>BetterBetter</h1>
              <h3>Unterstützen Sie jetzt Ihren Verein!</h3>
            </div>
            <div className="buttonDivLP">
              <button
                onClick={() => handleClicked("login")}
                className="landingPageLginButton"
              >
                Login
              </button>
              <p>oder</p>
              <button
                onClick={() => handleClicked("vereinAnmelden")}
                className="landingPageRegButton"
              >
                Verein anmelden?
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="apLogOrRegDiv">
          {loginOrRegister === "login" ? (
            <Login handleClick={handleClicked} />
          ) : loginOrRegister === "register" ? (
            <Register handleClick={handleClicked} />
          ) : (
            <VereinAnmelden handleClick={handleClicked} />
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
