import React, { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { format } from "date-fns";
import ExpandedGame from "../sideComponents/ExpandedGame";
import ExpandedPastGame from "../sideComponents/ExpandedPastGame";
import useUserData from "./useUserData";
import useGames from "./useGames";
import "./style/Start.css";

const Start = ({ onComponentChange, activeComponent }) => {
  const { userData, userBets } = useUserData();
  const { upcomingGames, pastGames } = useGames();
  const [expandedGame, setExpandedGame] = useState(null);
  const [expandedPastGame, setExpandedPastGame] = useState(null);
  const [pastBettedGames, setPastBettedGames] = useState([]);

  useEffect(() => {
    if (userData && userBets) {
      const games = pastGames.filter((game) =>
        userBets.some((bet) => bet.gameId === game.id)
      );
      setPastBettedGames(games);
    }
  }, [pastGames, userBets, userData]);

  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    handleResize(); // Set the initial value

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      alert("Benutzer wurde abgemeldet");
    } catch (error) {
      console.error("Fehler beim Abmelden:", error.message);
    }
  };

  const getBetStatus = (gameId) => {
    const bet = userBets.find((bet) => bet.gameId === gameId);
    if (!bet) return "gray";
    const game = pastGames.find((game) => game.id === gameId);
    if (!game || !game.ergebnis) return "gray";

    // Überprüfe, ob eines der Ergebnisse mit dem tatsächlichen Ergebnis übereinstimmt
    const isWinningBet = bet.ergebnis.some(
      (result) => result === game.ergebnis
    );
    return isWinningBet ? "green" : "red";
  };

  const getBetForGame = (gameId) => {
    const bet = userBets.find((bet) => bet.gameId === gameId);
    return bet ? bet.ergebnis : null;
  };

  const handleExpandGame = (gameId) => {
    const game = upcomingGames.find((game) => game.id === gameId);
    setExpandedGame(game);
  };

  const handleExpandPastGame = (gameId) => {
    const game = pastGames.find((game) => game.id === gameId);
    setExpandedPastGame(game);
    console.log(game);
  };

  const GameListFutur = ({ games, title }) => (
    <div className="start80Div">
      <div className="startSubHeadingDiv color1">
        <h2>{title}</h2>
      </div>
      <div className="startWettenDiv">
        {games.length > 0 ? (
          games.slice(0, 3).map((game) => {
            const bet = getBetForGame(game.id);
            return (
              <div
                className="startWetteDiv"
                key={game.id}
                onClick={() => handleExpandGame(game.id)}
              >
                <div className="startWetteInfoDiv">
                  <div className="startMannschaftenDiv">
                    <p>
                      {game.heimMannschaft} vs {game.auswärtsMannschaft}
                    </p>
                  </div>
                  <div className="startDatumDiv">
                    <p>{format(game.date.toDate(), "dd.MM.yyyy")}</p>
                  </div>
                </div>
                <div className={`startErgebnisDiv ${bet ? "orange" : ""}`}>
                  <p>{bet ? bet[0] : "- : -"}</p>
                </div>
              </div>
            );
          })
        ) : (
          <p>Keine Spiele in {title}</p>
        )}
      </div>
    </div>
  );

  const GameListPast = ({ games, title }) => (
    <div className="start80Div">
      <div className="startSubHeadingDiv color2">
        <h2>{title}</h2>
      </div>
      <div className="startWettenDiv">
        {games.length > 0 ? (
          games.slice(-3).map((game) => (
            <div
              className="startWetteDiv"
              key={game.id}
              onClick={() => handleExpandPastGame(game.id)}
            >
              <div className="startWetteInfoDiv">
                <div className="startMannschaftenDiv">
                  <p>
                    {game.heimMannschaft} vs {game.auswärtsMannschaft}
                  </p>
                </div>
                <div className="startDatumDiv">
                  <p>{format(game.date.toDate(), "dd.MM.yyyy")}</p>
                </div>
              </div>
              <div className={`startErgebnisDiv ${getBetStatus(game.id)}`}>
                <p>{game.ergebnis || "No result yet"}</p>
              </div>
            </div>
          ))
        ) : (
          <p>Keine Spiele in {title}</p>
        )}
      </div>
    </div>
  );

  const handleButtonClick = (comp, gameId) => {
    onComponentChange(comp, gameId);
  };

  return (
    <div className="startMainDiv">
      <div className="startHeader">
        <h1>Hallo {userData ? `${userData.firstName}!` : "Loading..."}</h1>
        <button onClick={logout}>
          <LogoutIcon style={{ fontSize: "1.8rem", color: "white" }} />
        </button>
      </div>
      <div className="startContent">
        {!expandedGame && !expandedPastGame ? (
          <div className="startContent">
            <div className="startInfoDiv">
              <div className="startInnerInfoDiv">
                <h1>
                  {userData
                    ? `${parseFloat(userData.kontoStand).toFixed(2)}€`
                    : "Loading..."}
                </h1>
                <p>Dein aktueller Kontostand</p>
              </div>
            </div>
            <div className="overflowAutoStart">
              <GameListFutur games={upcomingGames} title="Anstehende Spiele" />
              <GameListPast games={pastGames} title="Vergangene Spiele" />
            </div>
          </div>
        ) : expandedGame ? (
          <div className="startContent">
            <ExpandedGame
              auswärtsMannschaft={expandedGame.auswärtsMannschaft}
              heimMannschaft={expandedGame.heimMannschaft}
              date={expandedGame.date}
              bets={userBets}
              gameId={expandedGame.id}
              onComponentChange={handleButtonClick}
            />
          </div>
        ) : (
          <div className="startContent">
            <ExpandedPastGame
              auswärtsMannschaft={expandedPastGame.auswärtsMannschaft}
              heimMannschaft={expandedPastGame.heimMannschaft}
              date={expandedPastGame.date}
              bets={userBets}
              gameId={expandedPastGame.id}
              onClose={() => setExpandedPastGame(null)}
            />
          </div>
        )}
      </div>
      <div className="navBar">
        <div
          className={
            activeComponent === "start"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
        >
          <div
            className="navBarIconDiv"
            onClick={() => {
              handleButtonClick("start");
              setExpandedGame(null);
              setExpandedPastGame(null);
            }}
          >
            <HomeIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
        <div
          className={
            activeComponent === "add"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
        >
          <div
            className="navBarIconDiv navbarHighlightDiv"
            onClick={() => handleButtonClick("add")}
          >
            <AddIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
        <div
          className={
            activeComponent === "settings"
              ? "navBarActiveButtonDiv"
              : "navBarButtonDiv"
          }
          onClick={() => handleButtonClick("settings")}
        >
          <div className="navBarIconDiv">
            <SettingsIcon style={{ fontSize: "1.8rem", color: "white" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
