import { useState, useEffect } from "react";
import { auth, db } from "../../firebase-config";
import { doc, getDoc, collection, getDocs, setDoc } from "firebase/firestore";
import { useAuth } from "../loginOrRegister/AuthContext";

const useUserData = () => {
  const { user } = useAuth(); // Hole den aktuellen Benutzer aus dem Auth-Kontext
  const [userData, setUserData] = useState(null);
  const [userBets, setUserBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateUserData = async (updatedData) => {
    if (auth.currentUser) {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      try {
        await setDoc(userDocRef, updatedData, { merge: true });
        setUserData(updatedData);
      } catch (error) {
        console.error("Error updating user data:", error);
      }
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        setError("Benutzer nicht angemeldet");
        setLoading(false);
        return;
      }

      try {
        const userDoc = doc(db, "users", user.uid);
        const userSnap = await getDoc(userDoc);

        if (!userSnap.exists()) {
          setError("Keine Benutzerdaten gefunden");
          setLoading(false);
          return;
        }

        const data = userSnap.data();
        console.log("Benutzerdaten:", data); // Debugging-Log
        setUserData(data);

        const betsCollection = collection(db, "users", user.uid, "bet");
        const betsSnapshot = await getDocs(betsCollection);
        const bets = betsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Bets:", bets); // Debugging-Log
        setUserBets(bets);
      } catch (error) {
        console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        setError("Fehler beim Abrufen der Benutzerdaten");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  return { userData, userBets, loading, error, updateUserData };
};

export default useUserData;
