import React, { useState, useRef, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config";
import "./style/Login.css";
import { ReactComponent as Logo } from "../resources/logoBetterBetSVG1.svg";

const Login = (props) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const emailInputRef = useRef(null);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const login = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log("User logged in:", userCredential.user);
      // Optional: Redirect or handle successful login
    } catch (error) {
      console.error("Login error:", error);

      switch (error.code) {
        case "auth/invalid-credential":
          alert("Ungültige Anmeldeinformationen");
          break;
        case "auth/user-not-found":
          alert("Benutzer nicht gefunden");
          break;
        case "auth/wrong-password":
          alert("Falsches Passwort");
          break;
        default:
          alert("Anmeldung fehlgeschlagen: " + error.message);
          break;
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  const handleClicked = () => {
    props.handleClick("register");
  };

  return (
    <div className="width100height100flex">
      <div className="lginMainDiv">
        <h3 className="lginHeading">
          Benutzer <span className="highlightFont"> Anmelden</span>
        </h3>
        <form onSubmit={handleSubmit}>
          <input
            ref={emailInputRef}
            className="input lginInput"
            placeholder="Email..."
            value={loginEmail}
            onChange={(e) => setLoginEmail(e.target.value)}
          />
          <input
            className="input lginInput"
            type="password"
            placeholder="Passwort..."
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
          />
          <button className="lginSubmitButton" type="submit">
            Login
          </button>
        </form>
        <p style={{ marginTop: "1rem", marginBottom: "0.3rem" }}>Neu hier?</p>
        <button className="apButton" onClick={handleClicked}>
          Register
        </button>
      </div>
      <div className="halfDivAP">
        <Logo width="350" height="auto" /> <p>exSolution®</p>
      </div>
    </div>
  );
};

export default Login;
