// fetchWinnersAndUpdateBalances.js
import {
  doc,
  updateDoc,
  getDocs,
  getDoc,
  collection,
  query,
  where
} from 'firebase/firestore'
import { db } from '../../firebase-config'

const fetchWinnersAndUpdateBalances = async (vereinId, gameId, result) => {
  try {
    // Update the game result
    const gameRef = doc(db, 'vereine', vereinId, 'games', gameId)
    await updateDoc(gameRef, { ergebnis: result })

    // Fetch all users
    const usersSnapshot = await getDocs(collection(db, 'users'))
    const users = usersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))

    let totalEinsatz = 0
    let winningBets = []
    const winners = []

    // Process bets for each user
    for (const user of users) {
      const betsQuery = query(
        collection(db, 'users', user.id, 'bet'),
        where('gameId', '==', gameId)
      )
      const betsSnapshot = await getDocs(betsQuery)
      const bets = betsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))

      for (const bet of bets) {
        totalEinsatz += bet.einsatz
        const isWinningBet = bet.ergebnis.includes(result)

        if (isWinningBet) {
          winningBets.push({ userId: user.id, einsatz: bet.einsatz })
          winners.push(user.id)
        }

        // Remove the processed bet from the user's bets
        await updateDoc(doc(db, 'users', user.id), {
          bet: bets.filter(b => b.id !== bet.id)
        })
      }
    }

    // Calculate winnings
    const totalAmount = totalEinsatz
    const remainingAmount = totalAmount / 2
    const winningAmount = totalAmount / 2
    const winningShare =
      winningBets.length > 0 ? winningAmount / winningBets.length : 0

    console.log(`Gesamteinsatz: ${totalAmount}€`)
    console.log(`Restlicher Gewinn: ${remainingAmount}€`)
    console.log(`Gewinneranzahl: ${winningBets.length}`)
    console.log(`Gewinnbetrag pro Gewinner: ${winningShare}€`)

    // Update winners' balances
    for (const winBet of winningBets) {
      const userRef = doc(db, 'users', winBet.userId)
      const userSnap = await getDoc(userRef)
      const userData = userSnap.data()
      const updatedKontoStand = userData.kontoStand + winningShare
      const newTransaction = {
        betrag: winningShare,
        date: new Date(),
        game: gameId,
        type: 'aufladen'
      }

      await updateDoc(userRef, {
        kontoStand: updatedKontoStand,
        transaktionen: [...(userData.transaktionen || []), newTransaction]
      })

      console.log(
        `Benutzer: ${winBet.userId} erhält ${winningShare}€, neuer Kontostand: ${updatedKontoStand}€`
      )
    }

    // Update game with financial details
    await updateDoc(gameRef, {
      winners: winners,
      moneyPerWinner: winningShare,
      totalIncome: totalAmount,
      effectiveIncome: remainingAmount
    })
  } catch (error) {
    console.error('Fehler bei der Auswertung der Wetten:', error)
  }
}

export default fetchWinnersAndUpdateBalances
