import React from "react";
import { format } from "date-fns";
import "../dashboard/style/ExpandedGame.css";

const ExpandedPastGame = ({
  auswärtsMannschaft,
  heimMannschaft,
  date,
  bets,
  gameId,
  onClose,
}) => {
  const getBetsForGame = (gameId) => {
    return bets.filter((bet) => bet.gameId === gameId);
  };

  const renderBetResults = (bet) => {
    return bet.ergebnis.map((result, index) => (
      <div className="expandedGameResult" key={index}>
        <div>
          <p>Ergebnis {index + 1}:</p>
          <p>{result}</p>
        </div>
      </div>
    ));
  };

  const betsForGame = getBetsForGame(gameId);

  console.log("ExpandedPastGame Props: ", {
    auswärtsMannschaft,
    heimMannschaft,
    date,
    betsForGame,
    gameId,
  });

  return (
    <div className="expandedGame">
      <div className="expandedGameInfoDiv">
        <div className="expandedGameTeamsDiv">
          <p>
            {heimMannschaft} vs {auswärtsMannschaft}
          </p>
        </div>
        <div className="expandedGameDateDiv">
          <p>
            {format(date instanceof Date ? date : date.toDate(), "dd.MM.yyyy")}
          </p>
        </div>
        <button
          onClick={onClose}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "black",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          ×
        </button>
      </div>
      <div className="expandedGameBets">
        {betsForGame.length > 0 ? (
          betsForGame.map((bet) => (
            <div className="expandedGameBet" key={bet.id}>
              <div className="expandedGameResults">{renderBetResults(bet)}</div>
            </div>
          ))
        ) : (
          <p>Keine Wetten für dieses Spiel</p>
        )}
      </div>
    </div>
  );
};

export default ExpandedPastGame;
