import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { db, auth } from "../../firebase-config";
import "../dashboard/style/ExpandedGame.css";

const ExpandedGame = ({
  auswärtsMannschaft,
  heimMannschaft,
  date,
  bets,
  gameId,
  onComponentChange,
}) => {
  const [currentBets, setCurrentBets] = useState(bets);
  const [userData, setUserData] = useState(null);
  const [addErgebnisValue, setAddErgebnisValue] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.currentUser) {
        console.error("Benutzer nicht angemeldet");
        return;
      }

      const userDoc = doc(db, "users", auth.currentUser.uid);
      const userSnap = await getDoc(userDoc);

      if (userSnap.exists()) {
        setUserData(userSnap.data());
      } else {
        console.log("Keine Benutzerdaten gefunden");
      }
    };

    fetchUserData();
  }, []);

  const isBetOnGame = () => {
    return currentBets.some((bet) => bet.gameId === gameId);
  };

  const getBetsForGame = (gameId) => {
    return currentBets.filter((bet) => bet.gameId === gameId);
  };

  const handleDeleteResult = async (bet, resultIndex) => {
    const user = auth.currentUser;
    if (!user) {
      console.error("Benutzer nicht angemeldet");
      return;
    }
    const userId = user.uid;

    const betDocRef = doc(db, "users", userId, "bet", bet.id);

    if (bet.ergebnis.length <= 1) {
      const shouldDeleteWholeBet = window.confirm(
        "Möchten Sie die gesamte Wette löschen?"
      );
      if (shouldDeleteWholeBet) {
        try {
          await deleteDoc(betDocRef);

          setCurrentBets((prevBets) => prevBets.filter((b) => b.id !== bet.id));

          const newKontoStand = userData.kontoStand + bet.einsatz;

          const userDocRef = doc(db, "users", userId);
          await updateDoc(userDocRef, {
            kontoStand: newKontoStand,
          });

          setUserData((prevUserData) => ({
            ...prevUserData,
            kontoStand: newKontoStand,
          }));

          console.log(`Deleted bet ${bet.id}`);
          return;
        } catch (error) {
          console.error("Fehler beim Löschen der Wette:", error);
          return;
        }
      } else {
        return;
      }
    }

    const updatedErgebnis = bet.ergebnis.filter(
      (_, index) => index !== resultIndex
    );

    try {
      const newEinsatz = bet.einsatz - 1;
      const newKontoStand = userData.kontoStand + 1;

      await updateDoc(betDocRef, {
        ergebnis: updatedErgebnis,
        einsatz: newEinsatz,
      });

      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        kontoStand: newKontoStand,
      });

      setCurrentBets((prevBets) =>
        prevBets.map((b) =>
          b.id === bet.id
            ? { ...b, ergebnis: updatedErgebnis, einsatz: newEinsatz }
            : b
        )
      );

      setUserData((prevUserData) => ({
        ...prevUserData,
        kontoStand: newKontoStand,
      }));

      console.log(`Deleted result at index ${resultIndex} from bet ${bet.id}`);
    } catch (error) {
      console.error("Fehler beim Löschen des Ergebnisses:", error);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    let formattedValue = value;

    if (value.length === 2 && value[1] !== ":") {
      formattedValue = value[0] + ":" + value[1];
    } else if (value.length > 3) {
      formattedValue = value.slice(0, 3);
    }

    setAddErgebnisValue(formattedValue);
  };

  const handleAddBetSubmit = async (e) => {
    e.preventDefault();

    const user = auth.currentUser;
    if (!user) {
      console.error("Benutzer nicht angemeldet");
      return;
    }
    const userId = user.uid;

    try {
      const betId = getBetsForGame(gameId)[0].id;

      const existingBet = currentBets.find(
        (bet) =>
          bet.gameId === gameId && bet.ergebnis.includes(addErgebnisValue)
      );
      if (existingBet) {
        alert("Dieses Ergebnis wurde bereits für dieses Spiel platziert.");
        return;
      }

      const betDocRef = doc(db, "users", userId, "bet", betId);

      const betDocSnap = await getDoc(betDocRef);
      if (!betDocSnap.exists()) {
        console.error("Das Wettdokument wurde nicht gefunden.");
        return;
      }

      const currentBetData = betDocSnap.data();

      const updatedErgebnis = [...currentBetData.ergebnis, addErgebnisValue];
      const updatedEinsatz = currentBetData.einsatz + 1;

      await updateDoc(betDocRef, {
        ergebnis: updatedErgebnis,
        einsatz: updatedEinsatz,
      });

      const newKontoStand = userData.kontoStand - 1;

      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        kontoStand: newKontoStand,
      });

      setUserData((prevUserData) => ({
        ...prevUserData,
        kontoStand: newKontoStand,
      }));

      setCurrentBets((prevBets) =>
        prevBets.map((bet) =>
          bet.gameId === gameId
            ? { ...bet, ergebnis: updatedErgebnis, einsatz: updatedEinsatz }
            : bet
        )
      );

      alert("Ergebnis erfolgreich hinzugefügt!");

      setAddErgebnisValue("");
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Ergebnisses:", error);
      alert(
        "Leider ist ein Fehler aufgetreten (" +
          error.message +
          "). Bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <div className="expandedGame">
      <div className="expandedGameInfoDiv">
        <div className="expandedGameTeamsDiv">
          <p>
            {heimMannschaft} vs {auswärtsMannschaft}
          </p>
        </div>
        <div className="expandedGameDateDiv">
          <p>
            {format(date instanceof Date ? date : date.toDate(), "dd.MM.yyyy")}
          </p>
        </div>
      </div>
      {isBetOnGame() ? (
        <div className="expandedGameBets">
          {getBetsForGame(gameId).map((bet) => (
            <div className="expandedGameBet" key={bet.id}>
              <div className="expandedGameResults">
                {bet.ergebnis.map((result, index) => (
                  <div className="expandedGameResult" key={index}>
                    <div>
                      <p>Ergebnis {index + 1}:</p>
                      <p>{result}</p>
                    </div>
                    <div>
                      <DeleteIcon
                        onClick={() => handleDeleteResult(bet, index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="expandedGameFormDiv">
            <form onSubmit={handleAddBetSubmit}>
              <label htmlFor={"addErgebnis"}>Ergebnis:</label>
              <div className="row">
                <input
                  placeholder="- : -"
                  type="text"
                  id={"addErgebnis"}
                  name={"addErgebnis"}
                  value={addErgebnisValue || ""}
                  onChange={handleChange}
                  required
                  maxLength={3}
                />
                <button type="submit">
                  <AddIcon />
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="expandedGameAddBetsBtn">
          <p>Noch keine Wetten vorhanden, Wette platzieren?</p>
          <button onClick={() => onComponentChange("add", gameId)}>
            Add Bet
          </button>
        </div>
      )}
    </div>
  );
};

export default ExpandedGame;
